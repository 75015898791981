const TWITCH_AUTH_URL = 'https://id.twitch.tv/oauth2/token';
const TWITCH_API_URL = 'https://api.twitch.tv/helix';

export class TwitchService {
  constructor() {
    this.clientId = import.meta.env.VITE_TWITCH_CLIENT_ID;
    this.clientSecret = import.meta.env.VITE_TWITCH_CLIENT_SECRET;
    this.accessToken = null;
  }

  async validateCredentials() {
    if (!this.clientId || !this.clientSecret) {
      throw new Error('Les identifiants Twitch ne sont pas configurés');
    }
  }

  async getAccessToken() {
    try {
      await this.validateCredentials();

      const response = await fetch(TWITCH_AUTH_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: this.clientId,
          client_secret: this.clientSecret,
          grant_type: 'client_credentials'
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Échec de l\'authentification Twitch');
      }

      const data = await response.json();
      this.accessToken = data.access_token;
      return this.accessToken;
    } catch (error) {
      throw new Error(`Erreur d'authentification Twitch: ${error.message}`);
    }
  }

  async fetchStreams() {
    try {
      if (!this.accessToken) {
        await this.getAccessToken();
      }

      const response = await fetch(
        `${TWITCH_API_URL}/streams?game_id=32982&first=100`,
        {
          headers: {
            'Client-ID': this.clientId,
            'Authorization': `Bearer ${this.accessToken}`
          }
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          this.accessToken = null;
          return this.fetchStreams();
        }
        const error = await response.json();
        throw new Error(error.message || 'Échec de la récupération des streams');
      }

      const data = await response.json();
      return this.filterMoonlightStreams(data.data);
    } catch (error) {
      throw new Error(`Erreur lors de la récupération des streams: ${error.message}`);
    }
  }

  filterMoonlightStreams(streams) {
    const keywords = ['[Moonlight RP]', 'Moonlight RP', 'MoonlightRP','[MoonlightRP]'];
    return streams.filter(stream =>
      keywords.some(keyword => 
        stream.title.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  }
}