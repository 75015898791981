import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaTwitch, FaPlay, FaBook } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';
import { TwitchService } from '../services/twitch';

export default function Stream() {
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const twitchService = new TwitchService();

  const benefits = [
    {
      icon: <FaPlay className="w-6 h-6" />,
      title: "Devenez une Star",
      description: "Construisez votre communauté et devenez un streamer reconnu sur Moonlight RP"
    },
    {
      icon: <FaPlay className="w-6 h-6" />,
      title: "Évoluez Rapidement",
      description: "Profitez de notre soutien et de nos événements pour développer votre chaîne"
    },
    {
      icon: <FaPlay className="w-6 h-6" />,
      title: "Avantages Exclusifs",
      description: "Accédez à des fonctionnalités spéciales et des événements réservés aux streamers"
    }
  ];

  const fetchStreams = async () => {
    try {
      setLoading(true);
      setError(null);
      const moonlightStreams = await twitchService.fetchStreams();
      setStreams(moonlightStreams);
    } catch (err) {
      setError(err.message);
      setStreams([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStreams();
    const interval = setInterval(fetchStreams, 300000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen pt-24 px-4">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600 bg-clip-text text-transparent">
            Streams en Direct
          </h1>
          <p className={`text-lg max-w-2xl mx-auto ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Découvrez les créateurs de contenu qui diffusent actuellement leurs aventures sur Moonlight RP
          </p>
        </motion.div>

        {error && (
          <div className={`text-center p-4 rounded-lg mb-8 ${
            isDark ? 'bg-red-900/50 text-red-200' : 'bg-red-100 text-red-800'
          }`}>
            {error}
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : streams.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {streams.map((stream) => (
              <motion.a
                key={stream.id}
                href={`https://twitch.tv/${stream.user_login}`}
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`${
                  isDark 
                    ? 'bg-gray-800/50 hover:bg-gray-700/50' 
                    : 'bg-white hover:bg-gray-50'
                } rounded-lg overflow-hidden shadow-lg transition-all duration-300`}
              >
                <div className="relative">
                  <img
                    src={stream.thumbnail_url.replace('{width}', '440').replace('{height}', '248')}
                    alt={stream.title}
                    className="w-full aspect-video object-cover"
                  />
                  <div className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded text-sm">
                    {stream.viewer_count} spectateurs
                  </div>
                </div>
                <div className="p-4">
                  <h3 className={`font-semibold mb-2 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
                    {stream.user_name}
                  </h3>
                  <p className={`text-sm ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                    {stream.title}
                  </p>
                </div>
              </motion.a>
            ))}
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`text-center p-12 rounded-xl ${
              isDark 
                ? 'bg-gray-800/50 border-gray-700' 
                : 'bg-gray-50 border-gray-200'
            } border`}
          >
            <h3 className={`text-xl font-semibold mb-2 ${
              isDark ? 'text-gray-200' : 'text-gray-800'
            }`}>
              Aucun stream en cours
            </h3>
            <p className={`mb-6 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Soyez le premier à lancer votre stream et à captiver notre communauté !
            </p>
          </motion.div>
        )}

        <div className="mt-20">
          <motion.h2
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`text-3xl font-bold text-center mb-12 ${
              isDark ? 'text-gray-200' : 'text-gray-800'
            }`}
          >
            Pourquoi devenir streamer sur Moonlight RP ?
          </motion.h2>

          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`p-6 rounded-xl ${
                  isDark 
                    ? 'bg-gray-800/50 border-gray-700 hover:bg-gray-800' 
                    : 'bg-white border-gray-200 hover:bg-gray-50'
                } border transition-all duration-300 text-center group`}
              >
                <div className={`inline-flex p-4 rounded-xl mb-4 ${
                  isDark ? 'bg-purple-500/10' : 'bg-purple-100'
                } text-purple-500 group-hover:scale-110 transition-transform duration-300`}>
                  {benefit.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${
                  isDark ? 'text-gray-200' : 'text-gray-800'
                }`}>
                  {benefit.title}
                </h3>
                <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
                  {benefit.description}
                </p>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className={`p-8 rounded-xl ${
              isDark 
                ? 'bg-purple-500/10 border-purple-500/20' 
                : 'bg-purple-50 border-purple-100'
            } border text-center`}
          >
            <h3 className={`text-2xl font-semibold mb-4 ${
              isDark ? 'text-purple-400' : 'text-purple-600'
            }`}>
              Prêt à commencer l'aventure ?
            </h3>
            <p className={`mb-8 ${isDark ? 'text-gray-300' : 'text-gray-700'}`}>
              Nous avons préparé un guide complet pour vous aider à démarrer votre carrière de streamer dans les meilleures conditions
            </p>
            <Link
              to="/stream/guide"
              className="inline-flex items-center gap-2 px-8 py-4 rounded-lg bg-purple-600 text-white hover:bg-purple-500 transition-colors duration-300 text-lg font-semibold"
            >
              <FaBook className="w-5 h-5" />
              Accéder au Guide Complet
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
}