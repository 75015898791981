import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { siteConfig } from './config/site';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Rules from './components/Rules';
import Stream from './components/Stream';
import StreamGuide from './components/StreamGuide';
import Footer from './components/Footer';

export default function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-200">
          <Navbar config={siteConfig} />
          <Routes>
            <Route path="/" element={<Home config={siteConfig} />} />
            <Route path="/rules" element={<Rules config={siteConfig} />} />
            <Route path="/stream" element={<Stream config={siteConfig} />} />
            <Route path="/stream/guide" element={<StreamGuide config={siteConfig} />} />
          </Routes>
          <Footer config={siteConfig} />
        </div>
      </Router>
    </ThemeProvider>
  );
}