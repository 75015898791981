export const siteConfig = {
  name: "Moonlight RP",
  description: "Serveur GTA RP immersif et réaliste",
  logo: "/logo.png",
  
  links: {
    discord: "https://discord.gg/45C2cc6kP9",
    topServeur: "https://top-serveurs.net/gta/moonlight-rp",
    tiktok: "https://tiktok.com/@moonlight-rp",
  },

  streams: {
    platforms: {
      twitch: {
        enabled: true,
        clientId: import.meta.env.VITE_TWITCH_CLIENT_ID,
        requiredTags: ["Moonlight RP", "MoonlightRP", "[Moonlight]"],
      },
      kick: {
        enabled: true,
        requiredTags: ["Moonlight RP", "MoonlightRP", "[Moonlight]"],
      }
    },
    refreshInterval: 60000,
    apiUrl: import.meta.env.VITE_API_URL,
  },

  theme: {
    default: 'dark',
    colors: {
      primary: {
        400: '#a78bfa',
        500: '#8b5cf6',
        600: '#7c3aed',
      },
      secondary: {
        400: '#f472b6',
        500: '#ec4899',
        600: '#db2777',
      },
    },
    backgrounds: {
      home: "https://cdn.cloudflare.steamstatic.com/steam/apps/271590/ss_f64515607fd627aa952be91ee3c44ad2d90de89e.1920x1080.jpg",
      rules: "URL_DE_VOTRE_IMAGE_RULES",
      streams: "URL_DE_VOTRE_IMAGE_STREAMS",
    },
  },

  rules: {
    categories: [
      {
        title: "Règles Essentielles",
        icon: "shield",
        description: "Les règles fondamentales à respecter",
        rules: [
          "Restez dans votre personnage en toutes circonstances (No HRP)",
          "Le FearRP est obligatoire - Réagissez comme dans la vraie vie",
          "Pas de métagaming ou powergaming",
          "Pas de NLR (New Life Rule)",
          "Pas de force RP ou de freekill",
          "Micro de qualité obligatoire",
          "Communication en français uniquement"
        ]
      },
      {
        title: "Règles de Combat",
        icon: "combat",
        description: "Directives pour les interactions PvP",
        rules: [
          "Le combat logging est strictement interdit",
          "Les zones safes doivent être respectées",
          "Maximum 6 personnes par action",
          "Pas de braquage en zone safe",
          "Respect des délais entre braquages",
          "Scénario cohérent obligatoire",
          "Pas de spawn killing"
        ]
      }
    ]
  },

  seo: {
    title: "Moonlight RP - Serveur GTA RP",
    description: "Rejoignez l'aventure sur Moonlight RP, un serveur GTA RP immersif et réaliste. Whitelist ouverte !",
    keywords: ["GTA RP", "Moonlight", "FiveM", "Roleplay", "RP", "GTA 5"],
    ogImage: "/og-image.jpg",
  },
};