import React from 'react';
import { motion } from 'framer-motion';
import { FaDiscord, FaGamepad, FaUsers, FaCoins, FaTrophy } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

export default function Home() {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  return (
    <div className="pt-16">
      <div className="relative h-screen">
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://cdn.cloudflare.steamstatic.com/steam/apps/271590/ss_f64515607fd627aa952be91ee3c44ad2d90de89e.1920x1080.jpg?t=1695060909")',
          }}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center px-4"
          >
            <motion.div
              className="flex justify-center mb-6"
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img src="/logo.png" alt="Moonlight RP Logo" className="w-32 h-32" />
            </motion.div>
            <motion.h1 
              className="text-6xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Moonlight RP
            </motion.h1>
            <p className="text-xl md:text-2xl mb-8 text-gray-300">
              Plongez dans une expérience RP unique et immersive
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <motion.a
                href="#"
                whileHover={{ scale: 1.05 }}
                className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-4 rounded-lg font-semibold text-lg shadow-lg hover:shadow-purple-500/50 transition-all duration-300"
              >
                <FaGamepad className="inline-block mr-2" />
                Rejoindre le serveur
              </motion.a>
              <motion.a
                href="https://discord.gg/45C2cc6kP9"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                className="bg-[#5865F2] text-white px-8 py-4 rounded-lg font-semibold text-lg shadow-lg hover:shadow-[#5865F2]/50 transition-all duration-300"
              >
                <FaDiscord className="inline-block mr-2" />
                Discord
              </motion.a>
              <motion.a
                href="https://top-serveurs.net/gta/moonlight-rp"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white px-8 py-4 rounded-lg font-semibold text-lg shadow-lg hover:shadow-yellow-500/50 transition-all duration-300"
              >
                <FaTrophy className="inline-block mr-2" />
                TopServeur
              </motion.a>
            </div>
          </motion.div>
        </div>
      </div>

      <section className="py-20 px-4 bg-gray-900/50 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-transparent">
            Une expérience unique
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <FaGamepad className="w-8 h-8" />,
                title: "Roleplay Immersif",
                description: "Un environnement RP travaillé et réaliste avec des scripts exclusifs"
              },
              {
                icon: <FaUsers className="w-8 h-8" />,
                title: "Votre sécurité nous importes",
                description: "Nous avons investis dans le meilleur anticheat de FiveM."
              },
              {
                icon: <FaCoins className="w-8 h-8" />,
                title: "Économie Dynamique",
                description: "Un système économique équilibré avec de nombreux métiers et activités"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                viewport={{ once: true }}
                className={`${
                  isDark 
                    ? 'bg-gray-800/50 border-gray-700' 
                    : 'bg-white/90 border-gray-200'
                } backdrop-blur-sm p-8 rounded-xl transition-all duration-300 border group hover:border-purple-500/50`}
              >
                <div className={`${
                  isDark ? 'text-purple-400' : 'text-purple-600'
                } mb-4 group-hover:text-purple-500 transition-colors duration-300`}>
                  {feature.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-3 ${
                  isDark ? 'text-purple-400' : 'text-purple-600'
                } group-hover:text-purple-500`}>
                  {feature.title}
                </h3>
                <p className={`${
                  isDark ? 'text-gray-400' : 'text-gray-600'
                } group-hover:text-gray-300`}>
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}