import React from 'react';
import { FaDiscord, FaTiktok, FaHeart } from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

export default function Footer({ config }) {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const socialLinks = [
    { icon: <FaDiscord />, href: config.links.discord, label: 'Discord' },
    { icon: <FaTiktok />, href: config.links.tiktok, label: 'TikTok' }
  ];

  return (
    <footer className={`${
      isDark 
        ? 'bg-gray-900/50 border-gray-800' 
        : 'bg-gray-50 border-gray-200'
    } backdrop-blur-sm border-t`}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex justify-center space-x-6 mb-6">
            {socialLinks.map((social) => (
              <a
                key={social.label}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  isDark 
                    ? 'text-gray-400 hover:text-purple-400' 
                    : 'text-gray-600 hover:text-purple-600'
                } transition-colors duration-300 text-2xl`}
                aria-label={social.label}
              >
                {social.icon}
              </a>
            ))}
          </div>
          <p className={`flex items-center justify-center gap-2 ${
            isDark ? 'text-gray-400' : 'text-gray-600'
          }`}>
            © {new Date().getFullYear()} {config.name}. Créé avec <FaHeart className="text-purple-500" /> par notre équipe
          </p>
        </div>
      </div>
    </footer>
  );
}