import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaTwitch, 
  FaMicrophone, 
  FaDesktop, 
  FaCog, 
  FaDiscord,
  FaLightbulb,
  FaChartLine,
  FaHashtag
} from 'react-icons/fa';
import { SiKickstarter, SiObsstudio } from 'react-icons/si';
import { useTheme } from '../context/ThemeContext';

export default function StreamGuide() {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const sections = [
    {
      title: "Configuration Technique",
      icon: <FaCog className="w-8 h-8" />,
      content: [
        {
          subtitle: "Configuration OBS",
          icon: <SiObsstudio className="w-6 h-6" />,
          steps: [
            "Téléchargez et installez OBS Studio",
            "Configurez le bitrate entre 4000-6000 pour du 1080p",
            "Utilisez l'encodeur NVENC si disponible",
            "Réglez la fréquence d'images à 60 FPS",
            "Créez une scène dédiée à GTA RP"
          ]
        },
        {
          subtitle: "Matériel Recommandé",
          icon: <FaMicrophone className="w-6 h-6" />,
          steps: [
            "Microphone de qualité (USB ou XLR)",
            "Webcam 1080p (optionnelle)",
            "Connexion internet stable (upload min 10Mb/s)",
            "Processeur récent (i5/Ryzen 5 minimum)",
            "16GB RAM minimum"
          ]
        }
      ]
    },
    {
      title: "Configuration Twitch",
      icon: <FaTwitch className="w-8 h-8" />,
      content: [
        {
          subtitle: "Paramètres du Stream",
          icon: <FaDesktop className="w-6 h-6" />,
          steps: [
            "Activez l'authentification à deux facteurs",
            "Configurez les catégories favorites (GTA V)",
            "Créez des alertes personnalisées",
            "Configurez les commandes du chat",
            "Ajoutez des modérateurs de confiance"
          ]
        },
        {
          subtitle: "Optimisation",
          icon: <FaChartLine className="w-6 h-6" />,
          steps: [
            "Utilisez des tags pertinents (#RP, #FiveM)",
            "Créez un planning de stream régulier",
            "Interagissez avec votre chat",
            "Networker avec d'autres streamers",
            "Participez aux raids et hosts"
          ]
        }
      ]
    },
    {
      title: "Configuration Kick",
      icon: <SiKickstarter className="w-8 h-8" />,
      content: [
        {
          subtitle: "Paramètres de Base",
          icon: <FaCog className="w-6 h-6" />,
          steps: [
            "Complétez votre profil streamer",
            "Configurez les paramètres de stream",
            "Ajoutez une description détaillée",
            "Configurez les notifications",
            "Paramétrez la qualité du stream"
          ]
        },
        {
          subtitle: "Visibilité",
          icon: <FaHashtag className="w-6 h-6" />,
          steps: [
            "Utilisez des hashtags populaires",
            "Créez un titre accrocheur",
            "Ajoutez des miniatures personnalisées",
            "Partagez sur les réseaux sociaux",
            "Collaborez avec d'autres streamers"
          ]
        }
      ]
    }
  ];

  const tips = [
    "Restez toujours en personnage pendant le stream",
    "Interagissez régulièrement avec votre chat",
    "Créez des moments mémorables pour vos viewers",
    "Respectez les règles du serveur en tout temps",
    "Soyez régulier dans vos streams"
  ];

  return (
    <div className="min-h-screen pt-24 px-4 pb-12">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600 bg-clip-text text-transparent">
            Guide Complet du Streamer
          </h1>
          <p className={`text-lg max-w-2xl mx-auto ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Tout ce dont vous avez besoin pour démarrer votre aventure de streaming sur Moonlight RP
          </p>
        </motion.div>

        <div className="space-y-12">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`${
                isDark 
                  ? 'bg-gray-800/50 border-gray-700' 
                  : 'bg-white border-gray-200'
              } rounded-2xl border overflow-hidden shadow-lg`}
            >
              <div className="p-8">
                <div className="flex items-center gap-4 mb-8">
                  <div className={`p-4 rounded-xl ${
                    isDark ? 'bg-purple-500/10' : 'bg-purple-100'
                  } text-purple-500`}>
                    {section.icon}
                  </div>
                  <h2 className={`text-2xl font-bold ${
                    isDark ? 'text-gray-200' : 'text-gray-800'
                  }`}>
                    {section.title}
                  </h2>
                </div>

                <div className="grid md:grid-cols-2 gap-8">
                  {section.content.map((subsection, subIndex) => (
                    <div key={subIndex} className={`${
                      isDark 
                        ? 'bg-gray-900/50' 
                        : 'bg-gray-50'
                    } rounded-xl p-6`}>
                      <div className="flex items-center gap-3 mb-4">
                        <div className={`${
                          isDark ? 'text-purple-400' : 'text-purple-600'
                        }`}>
                          {subsection.icon}
                        </div>
                        <h3 className={`text-xl font-semibold ${
                          isDark ? 'text-gray-200' : 'text-gray-800'
                        }`}>
                          {subsection.subtitle}
                        </h3>
                      </div>
                      <ul className="space-y-3">
                        {subsection.steps.map((step, stepIndex) => (
                          <li key={stepIndex} className="flex items-start gap-3">
                            <span className={`w-6 h-6 flex items-center justify-center rounded-full ${
                              isDark 
                                ? 'bg-purple-500/20 text-purple-400' 
                                : 'bg-purple-100 text-purple-600'
                            } flex-shrink-0 text-sm font-semibold`}>
                              {stepIndex + 1}
                            </span>
                            <p className={isDark ? 'text-gray-300' : 'text-gray-700'}>
                              {step}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}

          {/* Tips Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className={`p-8 rounded-xl ${
              isDark 
                ? 'bg-purple-500/10 border-purple-500/20' 
                : 'bg-purple-50 border-purple-100'
            } border shadow-lg`}
          >
            <div className="flex items-start gap-6">
              <FaLightbulb className={`w-8 h-8 ${
                isDark ? 'text-purple-400' : 'text-purple-600'
              } flex-shrink-0`} />
              <div>
                <h3 className={`text-xl font-bold mb-4 ${
                  isDark ? 'text-purple-400' : 'text-purple-600'
                }`}>
                  Conseils pour Réussir
                </h3>
                <ul className="space-y-3">
                  {tips.map((tip, index) => (
                    <li key={index} className="flex items-center gap-3">
                      <span className={`w-2 h-2 rounded-full ${
                        isDark ? 'bg-purple-400' : 'bg-purple-600'
                      }`} />
                      <p className={isDark ? 'text-gray-300' : 'text-gray-700'}>
                        {tip}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </motion.div>

          {/* Support Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-center"
          >
            <h3 className={`text-xl font-bold mb-4 ${
              isDark ? 'text-gray-200' : 'text-gray-800'
            }`}>
              Besoin d'aide supplémentaire ?
            </h3>
            <p className={`mb-6 ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
              Notre équipe est là pour vous aider à démarrer votre aventure de streaming
            </p>
            <a
              href="#"
              className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-purple-600 text-white hover:bg-purple-500 transition-colors duration-300"
            >
              <FaDiscord className="w-5 h-5" />
              Rejoindre le Discord
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}