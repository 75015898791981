import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaShieldAlt, 
  FaUserSecret, 
  FaGavel, 
  FaExclamationTriangle
} from 'react-icons/fa';
import { useTheme } from '../context/ThemeContext';

export default function Rules() {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  const rules = [
    {
      icon: <FaShieldAlt className="w-8 h-8" />,
      title: "Règles Essentielles",
      description: "Les règles fondamentales à respecter",
      items: [
        "Restez dans votre personnage en toutes circonstances (No HRP)",
        "Le FearRP est obligatoire - Réagissez comme dans la vraie vie",
        "Pas de métagaming ou powergaming",
        "Pas de NLR (New Life Rule)",
        "Pas de force RP ou de freekill",
        "Micro de qualité obligatoire",
        "Communication en français uniquement"
      ]
    },
    {
      icon: <FaUserSecret className="w-8 h-8" />,
      title: "Règles de Combat",
      description: "Directives pour les interactions PvP",
      items: [
        "Le combat logging est strictement interdit",
        "Les zones safes doivent être respectées",
        "Maximum 6 personnes par action",
        "Pas de braquage en zone safe",
        "Respect des délais entre braquages",
        "Scénario cohérent obligatoire",
        "Pas de spawn killing"
      ]
    },
    {
      icon: <FaGavel className="w-8 h-8" />,
      title: "Sanctions",
      description: "Conséquences en cas d'infractions",
      items: [
        "Avertissement pour infraction mineure",
        "Kick temporaire en cas de récidive",
        "Ban temporaire pour infractions graves",
        "Ban définitif possible selon gravité",
        "Possibilité d'appel sur Discord",
        "Modération active 24/7",
        "Sanctions immédiates si nécessaire"
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-24 px-4 pb-12">
      <div className="max-w-5xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600 bg-clip-text text-transparent">
            Règlement du Serveur
          </h1>
          <p className={`text-lg max-w-2xl mx-auto ${isDark ? 'text-gray-400' : 'text-gray-600'}`}>
            Pour garantir une expérience roleplay optimale, nous vous prions de respecter ces règles
          </p>
        </motion.div>

        <div className="space-y-8">
          {rules.map((category, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`${
                isDark 
                  ? 'bg-gray-800/50 border-gray-700' 
                  : 'bg-white border-gray-200'
              } rounded-2xl border overflow-hidden shadow-lg`}
            >
              <div className="p-8">
                <div className="flex items-start gap-6 mb-8">
                  <div className={`p-4 rounded-xl ${
                    isDark ? 'bg-purple-500/10' : 'bg-purple-100'
                  } text-purple-500`}>
                    {category.icon}
                  </div>
                  <div>
                    <h2 className={`text-2xl font-bold mb-2 ${
                      isDark ? 'text-purple-400' : 'text-purple-600'
                    }`}>
                      {category.title}
                    </h2>
                    <p className={`${
                      isDark ? 'text-gray-400' : 'text-gray-600'
                    }`}>
                      {category.description}
                    </p>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-6">
                  {category.items.map((item, itemIndex) => (
                    <motion.div
                      key={itemIndex}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 + itemIndex * 0.05 }}
                      className={`flex items-center gap-4 p-4 rounded-xl ${
                        isDark 
                          ? 'bg-gray-900/50 hover:bg-gray-900/80' 
                          : 'bg-gray-50 hover:bg-gray-100'
                      } transition-colors duration-300`}
                    >
                      <div className={`w-2 h-2 rounded-full ${
                        isDark ? 'bg-purple-500' : 'bg-purple-400'
                      }`} />
                      <p className={`${
                        isDark ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        {item}
                      </p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className={`mt-12 p-8 rounded-xl ${
            isDark 
              ? 'bg-red-500/10 border-red-500/20' 
              : 'bg-red-50 border-red-100'
          } border`}
        >
          <div className="flex items-start gap-6">
            <FaExclamationTriangle className={`w-8 h-8 ${
              isDark ? 'text-red-400' : 'text-red-500'
            } flex-shrink-0 mt-1`} />
            <div>
              <h3 className={`text-xl font-bold mb-2 ${
                isDark ? 'text-red-400' : 'text-red-500'
              }`}>
                Important
              </h3>
              <p className={`${isDark ? 'text-gray-300' : 'text-gray-700'} mb-4`}>
                Le non-respect de ces règles entraînera des sanctions immédiates, pouvant aller de 
                l'avertissement au bannissement définitif selon la gravité de l'infraction.
              </p>
              <p className={`${isDark ? 'text-gray-400' : 'text-gray-600'} text-sm`}>
                Notre équipe de modération est présente 24/7 pour assurer le respect de ces règles et 
                maintenir une expérience RP de qualité pour tous les joueurs.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}